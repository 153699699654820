.telekardia{
    .occupy {
        width: 100%;
        height: 100%;
      }
      .recurrsiveIcon{
        top:0;
        right:0
      }
      .newpopover {
        opacity: 0.98;
        background:rgba(249, 249, 249, 0.78);
        backdrop-filter: blur(54.3656px);
        // background: rgba(249, 249, 249, 0.3);
        // backdrop-filter: blur(8px);
        border-radius: 13px; 
      }
    }